import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DuplicateIcon, LinkIcon } from '@heroicons/react/outline'
import { calculateTimeMultiplier } from './helper'
import useExchangeRates from '@/hooks/useExchangeRates'
import { successMsg } from '@/utils/toast'
import {
  Btcsv,
  DoubleCheck,
  LilbitLogo,
  LinkChain,
  Profile,
} from '@/icons/index'
import { XIcon } from '@heroicons/react/solid'

const getTimeMultiplierFormatter = (expiryDate, createdAt) =>
  calculateTimeMultiplier(createdAt, expiryDate)

const getAmountRenderer = (bsvToUsd, amount) => {
  const dollar = bsvToUsd(amount)
  return (
    <div className="text-sm font-medium">
      <div>$ {parseFloat(dollar).toFixed(4)}</div>
    </div>
  )
}

const PredictionModalData = ({ data, title, expiryDate, username }) => {
  const { bsvToUsd } = useExchangeRates()
  const intl = useIntl()
  const moveToBetLink = (txId) => {
    window.open(`https://whatsonchain.com/tx/${txId}`, '_blank')
  }
  return (
    <div className="rounded-b-xl bg-white/5 pt-6 px-6 overflow-auto scroller max-h-[calc(100vh-200px)] min-w-[23rem] md:min-w-[28rem] max-w-sm ">
      <div className="space-y-3 flex flex-col items-center">
        <div className="flex flex-row items-center justify-center text-base font-semibold font-inter">
          <h4>Prediction ID {data.id.slice(0, 6) + '...'}</h4>
          <div className="flex flex-row items-center gap-2 ml-2 relative w-14 justify-around">
            <DuplicateIcon
              className="shrink-0 w-7 h-7 cursor-pointer"
              onClick={() => {
                window.navigator.clipboard.writeText(data?.id)
                successMsg(
                  intl.formatMessage(
                    {
                      id: 'success.betIdCopied',
                      defaultMessage: `BetID ${data?.id} Successfully Copied`,
                    },
                    { betID: data?.id },
                  ),
                )
              }}
            />
          </div>
          <LinkChain
            className="shrink-0 w-6 h-6 cursor-pointer"
            onClick={() => {
              window.navigator.clipboard.writeText(
                `${window.location.host}/embed?betId=${data?.id}&game=prediction`,
              )
              successMsg(
                intl.formatMessage({
                  id: 'success.betSlipLinkCopied',
                  defaultMessage: 'Bet slip link successfully copied',
                }),
              )
            }}
          />
        </div>
        <div className="text-[#98A2B3] font-inter space-x-2 flex flex-row items-center justify-center">
          <h4 className="font-normal text-base">
            <FormattedMessage
              id="betSlipModal.placedBy.title"
              defaultMessage={'Placed By'}
            />
          </h4>
          <div className="bg-white/5 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
            <Profile className="w-5 h-5 text-gray-300" />
            <h4 className="font-medium text-sm capitalize">{username}</h4>
          </div>
        </div>
        <div className="font-inter font-normal text-xs">
          <FormattedMessage id="betSlipModal.on.text" defaultMessage={'on'} />{' '}
          {data.createdAt.toDate().toDateString()}
        </div>
      </div>
      <div className="mt-4 font-extrabold flex flex-wrap">{title}</div>
      <div className="mt-6 mb-2">
        <div className={'bg-white/5 overflow-hidden rounded-t-xl rounded-b-xl'}>
          <div className="bg-white/5 py-2 px-6 flex flex-row items-center justify-start gap-2 relative">
            <h4 className="overflow-ellipsis flex-1 text-left font-inter font-medium text-xs">
              Transaction Information
            </h4>
          </div>
          <div className="px-6 py-4">
            <div className="flex flex-col items-start">
              <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                Bet Tx
              </span>
              <div className="flex space-x-3 w-full">
                <div className="max-w-[250px] overflow-x-auto">
                  <span className="text-xs">{data.betTxId}</span>
                </div>
                <DuplicateIcon
                  className="shrink-0 w-5 h-5 cursor-pointer"
                  onClick={() => {
                    window.navigator.clipboard.writeText(data.betTxId)
                    successMsg(
                      intl.formatMessage(
                        {
                          id: 'success.betTxCopied"',
                          defaultMessage: `BetTx ${data.betTxId} Successfully Copied`,
                        },
                        { tx: data.betTxId },
                      ),
                    )
                  }}
                />
                <a
                  className="shrink-0"
                  href={`https://whatsonchain.com/tx/${data.betTxId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkIcon className="w-5 h-5 rotate-45 text-yellow-500" />
                </a>
              </div>
            </div>
          </div>
          {data?.payoutTxid && (
            <div className="px-6 py-4">
              <div className="flex flex-col items-start">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  Payout Tx
                </span>
                <div className="flex space-x-3 w-full">
                  <div className="max-w-[250px] overflow-x-auto">
                    <span className="text-xs">{data.payoutTxid}</span>
                  </div>
                  <DuplicateIcon
                    className="shrink-0 w-5 h-5 cursor-pointer"
                    onClick={() => {
                      window.navigator.clipboard.writeText(data.payoutTxid)
                      successMsg(
                        intl.formatMessage(
                          {
                            id: 'success.betTxCopied"',
                            defaultMessage: `PayoutTx ${data.payoutTxid} Successfully Copied`,
                          },
                          { tx: data.payoutTxid },
                        ),
                      )
                    }}
                  />
                  <a
                    className="shrink-0"
                    href={`https://whatsonchain.com/tx/${data.payoutTxid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon className="w-5 h-5 rotate-45 text-yellow-500" />
                  </a>
                </div>
              </div>
            </div>
          )}
          {data?.status === 'completed' && (
            <div className="flex flex-col items-center justify-between space-x-1 text-sm font-medium font-inter text-white relative">
              {data?.payoutTxid ? (
                <DoubleCheck className="w-6 h-6 text-green-500 stroke-2" />
              ) : (
                <XIcon className="w-6 h-6 text-red-500 stroke-2" />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center justify-center relative">
          <LilbitLogo className="w-[6.5rem] h-8" />
        </div>
        <div className="mt-3 mb-10 space-y-4 font-inter text-xs">
          <div className="flex flex-row items-center justify-between">
            <h3 className="font-semibold">Answer</h3>
            <h4 className="font-medium">{data.answer}</h4>
          </div>
          <div className="flex flex-row items-center justify-between">
            <h3 className="font-semibold">
              <FormattedMessage
                id="betSlipModal.totalBet.title"
                defaultMessage={'Total bet'}
              />
            </h3>
            <div
              className="font-medium hover:cursor-pointer"
              onClick={() => moveToBetLink(data?.betTxId)}
            >
              <span> {getAmountRenderer(bsvToUsd, data.amount)}</span>
              <div class="has-tooltip flex space-x-0.5">
                <span class="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                  {data.amount}
                </span>
                <Btcsv className="w-3 h-3" />
                <span>{Number(data.amount).toFixed(4)}</span>
              </div>
            </div>
          </div>
          {data?.payoutAmount ? (
            <>
              <div className="flex flex-row items-center justify-between">
                <h3 className="font-semibold">Payout</h3>

                <div
                  className="font-medium hover:cursor-pointer"
                  onClick={() => moveToBetLink(data?.payoutTxid)}
                >
                  <span>{getAmountRenderer(bsvToUsd, data.payoutAmount)}</span>
                  <div class="has-tooltip flex space-x-0.5">
                    <span class="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                      {data.payoutAmount}
                    </span>
                    <Btcsv className="w-3 h-3" />
                    <span>{Number(data.payoutAmount).toFixed(4)}</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-row items-center justify-between">
              <h3 className="font-semibold">Time Multiplier</h3>

              <h4 className="font-medium">
                {getTimeMultiplierFormatter(expiryDate, data.createdAt)}
              </h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PredictionModalData
